import React from "react";
import {
  createStyles,
  Chip,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";

/* from material-ui docs -- these control the appearance of the menu that pops up for select*/
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const SelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
      backgroundColor: theme.palette.grey[700],
      color: "#ffffff",
    },
    chipContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    formField: {
      margin: 20,
      width: "90%",
    },
  })
);

interface ObjectWithUuid {
  uuid: string;
}

interface ChipMultiSelectProps<T extends ObjectWithUuid, K extends keyof T> {
  value: string[];
  id: string;
  label: string;
  objects: T[];
  onChange: (evt: React.ChangeEvent<{ value: unknown }>) => void;
  labelLookup: (obj?: T) => T[K];
}

function ChipMultiSelect<T extends ObjectWithUuid, K extends keyof T>({
  value,
  id,
  label,
  objects,
  labelLookup,
  onChange,
}: ChipMultiSelectProps<T, K>) {
  const labelName = `${id}-label`;
  const classes = useStyles();
  return (
    <FormControl variant="filled" className={classes.formField}>
      <InputLabel id={labelName}>{label}</InputLabel>
      <Select
        id={id}
        labelId={labelName}
        multiple
        value={value}
        onChange={onChange}
        renderValue={(selected) => (
          <div className={classes.chipContainer}>
            {(selected as string[]).map((value) => (
              <Chip
                key={value}
                className={classes.chip}
                label={labelLookup(objects.find((obj) => obj.uuid === value))}
              />
            ))}
          </div>
        )}
        MenuProps={SelectMenuProps}
      >
        {objects.map((obj) => (
          <MenuItem key={obj.uuid} value={obj.uuid}>
            {labelLookup(obj)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ChipMultiSelect;
