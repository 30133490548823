import config from "./config";
import { listDemosFixture, listUsersFixture } from "./api_service_fixtures";
import {
  DemoFormInput,
  Feature,
  FeatureFormInput,
  OwnerFormInput,
  ProductFormInput,
  AuthFormInput,
} from "./types";
import {
  DemosResponse,
  ProductFeaturesResponse,
  OwnersResponse,
  UsersResponse,
  ProductsResponse,
  TokenResponse,
} from "./api_service_types";

export enum LoadingStateEnum {
  INIT = 1,
  FETCHING,
  RECEIVED,
  FAILED,
}

/* TODO: hook to initiate calls to services 
function useApiService(
  service: string,
  dispatch: any,
  requestData: any
): [LoadingStateEnum, any[], string | null] {
  const [requestState, setRequestState] = React.useState(LoadingStateEnum.INIT);
  return [requestState, [], null];
}

*/

export function authenticatedFetch(
  endpoint: string,
  requestConfig: RequestInit
): Promise<Response> {
  return fetch(endpoint, {
    ...requestConfig,
    headers: {
      ...requestConfig.headers,
      Authorization: `Bearer ${config.authToken}`,
    },
  });
}

export function emailAuth(authDetails: AuthFormInput): Promise<TokenResponse> {
  const body = {
    user_email: authDetails.email,
    user_password: authDetails.password,
  };

  return fetch(`${config.serverOrigin}/public/v1/auth_tokens`, {
    mode: "cors",
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(resp.json());
    }
    return resp.json();
  });
}

export function listUsers(): Promise<UsersResponse> {
  if (config.useFixtures) {
    return Promise.resolve(listUsersFixture) as Promise<UsersResponse>;
  }
  return authenticatedFetch(`${config.serverOrigin}/public/v1/users`, {
    mode: "cors",
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(resp.json());
    }
    return resp.json() as Promise<UsersResponse>;
  });
}

export function listDemos(): Promise<DemosResponse> {
  if (config.useFixtures) {
    return Promise.resolve(listDemosFixture) as Promise<DemosResponse>;
  }
  return authenticatedFetch(`${config.serverOrigin}/public/v1/demos`, {
    mode: "cors",
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(resp.json());
    }
    return resp.json() as Promise<DemosResponse>;
  });
}

export function deleteDemo(demoId: string): Promise<void> {
  return authenticatedFetch(
    `${config.serverOrigin}/public/v1/demos/${demoId}`,
    {
      mode: "cors",
      method: "DELETE",
    }
  ).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(resp.json());
    }
  });
}

export function createDemoReminder(demoId: string): Promise<void> {
  return authenticatedFetch(
    `${config.serverOrigin}/public/v1/demos/${demoId}/reminders`,
    {
      mode: "cors",
      method: "POST",
    }
  ).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(resp.json());
    }
  });
}

export function listFeatures(): Promise<Feature[]> {
  return Promise.resolve([]);
}

export function createDemo(
  demoFormState: DemoFormInput
): Promise<DemosResponse> {
  const body = {
    prospect: demoFormState.prospect,
    scheduled_time: demoFormState.scheduledTime,
    location: demoFormState.location,
    notes: demoFormState.notes,
    product_features_uuids: demoFormState.productFeaturesUuids,
  };
  return authenticatedFetch(`${config.serverOrigin}/public/v1/demos`, {
    mode: "cors",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(resp.json());
    }
    return resp.json();
  });
}

export function createProductFeature(
  featureFormState: FeatureFormInput
): Promise<ProductFeaturesResponse> {
  const body = {
    name: featureFormState.name,
    description: featureFormState.description,
    sales_pitch: featureFormState.salesPitch,
    availability_date: featureFormState.availabilityDate
      .toISOString()
      .replace(/T.*$/, ""),
    owner_uuids: featureFormState.ownerUuids,
  };
  return authenticatedFetch(
    `${config.serverOrigin}/public/v1/products/${featureFormState.productUuid}/features`,
    {
      mode: "cors",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    }
  ).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(resp.json());
    }
    return resp.json();
  });
}

export function createOwner(
  ownerFormState: OwnerFormInput
): Promise<OwnersResponse> {
  const user_uuid = ownerFormState.userUuid?.trim();
  const body = {
    fullname: ownerFormState.fullname,
    email: ownerFormState.email,
    position: ownerFormState.position,
    user_uuid: user_uuid || null,
  };
  return authenticatedFetch(`${config.serverOrigin}/public/v1/owners`, {
    mode: "cors",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(resp.json());
    }
    return resp.json();
  });
}

export function createProduct(
  productFormState: ProductFormInput
): Promise<ProductsResponse> {
  const body = {
    name: productFormState.name,
    description: productFormState.description,
    owner_uuid: productFormState.ownerUuid,
  };
  return authenticatedFetch(`${config.serverOrigin}/public/v1/products`, {
    mode: "cors",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(resp.json());
    }
    return resp.json();
  });
}
