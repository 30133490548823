/* BEGIN ACTION TYPES these should be moved to another module */
export const DEMO_LIST_RECEIVED = "demos/received";
export const DEMO_DELETED = "demos/deleted";
export const PRODUCT_FEATURES_LIST_RECEIVED = "product_features/received";
export const OWNERS_LIST_RECEIVED = "owners/received";
export const PRODUCTS_LIST_RECEIVED = "products/received";
export const USERS_LIST_RECEIVED = "users/received";
export const AUTH_TOKEN_RECEIVED = "auth/token/received";

export interface AuthTokenReceivedAction {
  type: typeof AUTH_TOKEN_RECEIVED;
  payload: string;
}

export interface UsersReceivedAction {
  type: typeof USERS_LIST_RECEIVED;
  payload: User[];
}

export interface DemosReceivedAction {
  type: typeof DEMO_LIST_RECEIVED;
  payload: Demo[];
}

export interface DemoDeletedAction {
  type: typeof DEMO_DELETED;
  payload: string;
}

export interface ProductFeaturesReceivedAction {
  type: typeof PRODUCT_FEATURES_LIST_RECEIVED;
  payload: Feature[];
}

export interface OwnersReceivedAction {
  type: typeof OWNERS_LIST_RECEIVED;
  payload: Owner[];
}

export interface ProductsReceivedAction {
  type: typeof PRODUCTS_LIST_RECEIVED;
  payload: Product[];
}

export type ActionType =
  | DemoDeletedAction
  | DemosReceivedAction
  | OwnersReceivedAction
  | ProductFeaturesReceivedAction
  | ProductsReceivedAction
  | AuthTokenReceivedAction
  | UsersReceivedAction;

/* END ACTION TYPES */

export interface AuthDetails {
  user: User;
  account: Account;
}

export interface User {
  uuid: string;
  fullname: string;
  email: string;
}

export interface Feature {
  name: string;
  uuid: string;
  ownersUuids: string[];
}

export interface Product {
  uuid: string;
  name: string;
  description: string | null;
  productFeatureUuids: string[];
}

export interface Demo {
  uuid: string;
  prospect: string;
  scheduledTime: Date;
  location: string;
  notes: string;
  productFeaturesUuids: string[] /* the actual product features will be fetched from the store */;
}

export interface Owner {
  uuid: string;
  email: string;
}

export interface AuthFormInput {
  email: string;
  password: string;
}

// rename to DemoFormInput
export interface DemoFormInput {
  prospect: string;
  scheduledTime: Date;
  location: string;
  productFeaturesUuids: string[];
  notes: string;
}

export interface FeatureFormInput {
  productUuid: string;
  name: string;
  description: string;
  availabilityDate: Date;
  salesPitch: string;
  ownerUuids: string[];
}

export interface OwnerFormInput {
  fullname?: string;
  email?: string;
  position?: string;
  userUuid?: string;
}

export interface ProductFormInput {
  name: string;
  description: string;
  ownerUuid: string;
}

export interface AppStateType {
  features: Feature[];
  demos: Demo[];
  owners: Owner[];
  products: Product[];
  users: User[];
}
