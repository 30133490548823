import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";

import { emailAuth } from "../api_service";
import config from "../config";

const loginStyles = (theme: Theme) =>
  createStyles({
    loginContainer: {
      position: "absolute",
      height: "100%",
    },
    form: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    formField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: "300px",
    },
  });

interface Props extends WithStyles<typeof loginStyles> {}

function LoginFormContainer(props: Props) {
  const { classes } = props;
  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [cookies, setCookie] = useCookies(["AUTH_TOKEN"]);
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();

  const { from } = location.state || { from: { pathname: "/" } };
  const formSubmitHandler = () => {
    // take care of submitting form
    setFormIsSubmitting(true);
    emailAuth({ email, password }).then((tokenResponse) => {
      setCookie("AUTH_TOKEN", tokenResponse.token, {
        path: "/",
        maxAge: 3600,
      });
      setFormIsSubmitting(false);
      history.replace(from);
    });
  };

  return (
    <Grid
      className={classes.loginContainer}
      container
      direction="column"
      alignItems="center"
      justify="center"
      spacing={2}
    >
      <Typography gutterBottom color="primary" variant="h2" component="h2">
      DemoPrep
      </Typography>
      {/* login form requires email/password */}
      <form
        className={classes.form}
        onKeyPress={(evt) => {
          if (evt.key === "Enter") {
            formSubmitHandler();
          }
        }}
      >
        <TextField
          className={classes.formField}
          variant="filled"
          id="login-form-email"
          required
          label="Email"
          value={email}
          placeholder="email@yourcompany.com"
          error={emailError}
          helperText={emailError || "Enter your email"}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(evt.target.value)
          }
        />
        <TextField
          className={classes.formField}
          variant="filled"
          id="login-form-password"
          required
          label="Password"
          type="password"
          value={password}
          error={passwordError}
          helperText={passwordError || "Enter your password"}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(evt.target.value)
          }
        />
        <Button
          fullWidth={true}
          variant="contained"
          color="primary"
          disabled={formIsSubmitting}
          onClick={formSubmitHandler}
        >
          Login
        </Button>
      </form>
    </Grid>
  );
}

export default withStyles(loginStyles)(LoginFormContainer);
