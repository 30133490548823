/* TODO: may remove this 
export function mapDemoResponseToDemos(demoResponse: DemosResponse): Demo[] {
  const { owners, product_features, demos } = demoResponse;
  const convertedDemos: Demo[] = demos.map((demo) => {
    // for each demo need to hydrate the product_features array
    const demoProductFeatures: Feature[] = demo.product_features_uuids.map(
      (productFeatureUuid) => {
        const productFeature = product_features.find(
          (product_feature) => product_feature.uuid === productFeatureUuid
        );
        if (!productFeature) {
          throw new Error(
            `ProductFeature(uuid=${productFeatureUuid}) has no corresponding product feature`
          );
        }
        const productFeatureOwners: Owner[] = productFeature.owners_uuids.map(
          (ownerUuid) => {
            const productFeatureOwner = owners.find(
              (owner) => owner.uuid === ownerUuid
            );
            if (!productFeatureOwner) {
              throw new Error(
                `Owner(uuid=${ownerUuid}) has no corresponding owner`
              );
            }
            return { ...productFeatureOwner };
          }
        );
        return { uuid: productFeature.uuid, name: productFeature.name };
      }
    );
    return {
      uuid: demo.uuid,
      prospect: demo.prospect,
      scheduledTime: new Date(demo.scheduled_time),
      location: demo.location,
      notes: demo.notes,
      productFeatures: demoProductFeatures,
    };
  });
  return convertedDemos;
}
*/

import { Demo, Feature, Owner, Product, User } from "./types";
import {
  DemoResponseItem,
  ProductFeatureResponseItem,
  OwnerResponseItem,
  ProductResponseItem,
  UserResponseItem,
} from "./api_service_types";

export function userResponseItemToUser(
  user_response_item: UserResponseItem
): User {
  return {
    ...user_response_item,
  };
}

export function demoResponseItemToDemo(
  demo_response_item: DemoResponseItem
): Demo {
  return {
    uuid: demo_response_item.uuid,
    prospect: demo_response_item.prospect,
    scheduledTime: new Date(demo_response_item.scheduled_time),
    location: demo_response_item.location,
    notes: demo_response_item.notes,
    productFeaturesUuids: demo_response_item.product_features_uuids,
  };
}

export function featureResponseItemToFeature(
  feature_response_item: ProductFeatureResponseItem
): Feature {
  return {
    uuid: feature_response_item.uuid,
    name: feature_response_item.name,
    ownersUuids: feature_response_item.owners_uuids,
  };
}

export function productResponseItemToProduct(
  product_response_item: ProductResponseItem
): Product {
  return {
    uuid: product_response_item.uuid,
    name: product_response_item.name,
    description: product_response_item.description,
    productFeatureUuids: product_response_item.product_features_uuids,
  };
}

export function ownerResponseItemToOwner(
  owner_response_item: OwnerResponseItem
): Owner {
  return {
    uuid: owner_response_item.uuid,
    email: owner_response_item.email,
  };
}
