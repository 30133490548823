import React, { useState, useReducer, useContext } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";

import AppContext from "../app_context";
import { ownersReceived } from "../actions";
import { OwnerFormInput } from "../types";
import { createOwner } from "../api_service";
import { ownerResponseItemToOwner } from "../api_service_data_mappers";
import { Divider } from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const SelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ownerCreateFormStyles = (theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      marginLeft: 2,
    },
    formField: {
      margin: 20,
      width: "90%",
    },
    paper: {
      width: 700,
      padding: "12px",
      margin: "auto",
      overflow: "scroll",
      maxHeight: 600,
      minHeight: 500,
      marginTop: "50px",
    },
  });
interface OwnerCreateFormProps
  extends WithStyles<typeof ownerCreateFormStyles> {
  open: boolean;
  onCloseModal: () => void;
  onSubmitForm: (newOwnerUuid: string) => void;
}

interface ownerFormState extends OwnerFormInput {
  errors: { [K in keyof OwnerFormInput]?: string | null };
}

const ownerFormInitialValues: ownerFormState = {
  fullname: "",
  email: "",
  position: "",
  userUuid: "",
  errors: {},
};

type formKeys = keyof OwnerFormInput;

const ownerFormReducer = (
  state: ownerFormState,
  action: { name: formKeys | "RESET"; value: string }
) => {
  if (action.name === "RESET") {
    return { ...ownerFormInitialValues };
  }

  return { ...state, [action.name]: action.value };
};

const OwnerCreateFormContainer: React.FC<OwnerCreateFormProps> = ({
  open,
  onCloseModal,
  onSubmitForm,
  classes,
}) => {
  const [formState, formDispatch] = useReducer(
    ownerFormReducer,
    ownerFormInitialValues
  );
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  if (!open) {
    return null;
  }
  const { users } = state;
  return (
    <Dialog
      open={open}
      onClose={onCloseModal}
      scroll="paper"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DialogTitle disableTypography={true} id="owner-create-form-title">
        <Typography variant="h4" component="h4">
          Add A Owner
        </Typography>
      </DialogTitle>

      <DialogContent>
        <form className={classes.form}>
          <FormControl variant="filled" className={classes.formField}>
            <InputLabel id="owner-create-form-user-label">User</InputLabel>
            <Select
              id="owner-create-form-user"
              labelId="owner-create-form-user-label"
              value={formState.userUuid}
              onChange={(evt: React.ChangeEvent<{ value: unknown }>) =>
                formDispatch({
                  name: "userUuid",
                  value: evt.target.value as string,
                })
              }
              MenuProps={SelectMenuProps}
            >
              {users.map((user) => (
                <MenuItem key={user.uuid} value={user.uuid}>
                  {user.fullname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* hide these forms if the userUuid is not none */}
          <Divider variant="middle" />

          <TextField
            variant="filled"
            id="owner-create-form-fullname"
            required
            className={classes.formField}
            label="Name"
            name="fullname"
            value={formState.fullname}
            error={!!formState.errors.fullname}
            helperText={
              formState.errors.fullname || "The full name of the user"
            }
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
              formDispatch({ name: "fullname", value: evt.target.value })
            }
          />
          <TextField
            variant="filled"
            id="owner-create-form-email"
            required
            className={classes.formField}
            label="Email Address"
            name="email"
            value={formState.email}
            error={!!formState.errors.email}
            helperText={
              formState.errors.email || "Where should notification be sent to?"
            }
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
              formDispatch({ name: "email", value: evt.target.value })
            }
          />
          <TextField
            variant="filled"
            id="owner-create-form-position"
            required
            className={classes.formField}
            label="Position"
            name="position"
            value={formState.position}
            error={!!formState.errors.position}
            helperText={
              formState.errors.position || "What is the position of the owner?"
            }
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
              formDispatch({ name: "position", value: evt.target.value })
            }
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth={false}
          variant="outlined"
          color="secondary"
          disabled={isFormSubmitting}
          onClick={() => {
            /* TODO: hackish way of clearing form! */
            formDispatch({ name: "RESET", value: "" });
            onCloseModal();
          }}
        >
          Go Back
        </Button>
        <Button
          fullWidth={false}
          variant="contained"
          color="primary"
          disabled={isFormSubmitting}
          onClick={() => {
            setIsFormSubmitting(true);
            createOwner(formState).then((ownerResponse) => {
              dispatch(
                ownersReceived(
                  ownerResponse.owners.map(ownerResponseItemToOwner)
                )
              );
              onSubmitForm(ownerResponse.owners[0].uuid);
              setIsFormSubmitting(false);
              formDispatch({ name: "RESET", value: "" });
            });
          }}
        >
          Add Owner
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(ownerCreateFormStyles)(OwnerCreateFormContainer);
