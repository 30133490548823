import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useCookies } from "react-cookie";
import { AppStateType } from "./types";
import { GlobalReducer } from "./reducer";
import AppContext from "./app_context";
import LoginContainer from "./containers/LoginContainer";
import DemoListContainer from "./containers/DemoListContainer";
import config from "./config";

const InitialAppState: AppStateType = {
  owners: [],
  features: [],
  demos: [],
  products: [],
  users: [],
};

//fetch
function App() {
  const [state, dispatch] = React.useReducer(GlobalReducer, InitialAppState);
  const [cookies] = useCookies(["AUTH_TOKEN"]);
  if (cookies.AUTH_TOKEN) {
    config.authToken = cookies.AUTH_TOKEN;
  }
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Router>
        <Switch>
          <Route path="/auth">
            <LoginContainer />
          </Route>
          <Route
            path="/"
            render={({ location }) => {
              if (cookies.AUTH_TOKEN) {
                return <DemoListContainer />;
              } else {
                return (
                  <Redirect
                    to={{ pathname: "/auth", state: { from: location } }}
                  />
                );
              }
            }}
          />
        </Switch>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
