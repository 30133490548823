let config: {
  serverOrigin: string;
  authToken: string;
  useFixtures: boolean;
};

if (process.env.NODE_ENV === "production") {
  config = {
    serverOrigin: "https://demoprep.herokuapp.com",
    authToken: "",
    useFixtures: false,
  };
} else if (process.env.NODE_ENV === "development") {
  config = {
    serverOrigin: "https://demoprep-dev.herokuapp.com",
    authToken: "",
    useFixtures: false,
  };
} else {
  config = {
    serverOrigin: "http://localhost:8000",
    authToken: "",
    useFixtures: false,
  };
}

export default config;
