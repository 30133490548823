import {
  DEMO_LIST_RECEIVED,
  DemosReceivedAction,
  Demo,
  Feature,
  ProductFeaturesReceivedAction,
  PRODUCT_FEATURES_LIST_RECEIVED,
  PRODUCTS_LIST_RECEIVED,
  Owner,
  OwnersReceivedAction,
  OWNERS_LIST_RECEIVED,
  ProductsReceivedAction,
  Product,
  USERS_LIST_RECEIVED,
  User,
  UsersReceivedAction,
  AUTH_TOKEN_RECEIVED,
  AuthTokenReceivedAction,
  DemoDeletedAction,
  DEMO_DELETED,
} from "./types";

export function authTokenReceived(token: string): AuthTokenReceivedAction {
  return { type: AUTH_TOKEN_RECEIVED, payload: token };
}

export function demosReceived(demos: Demo[]): DemosReceivedAction {
  return { type: DEMO_LIST_RECEIVED, payload: demos };
}

export function demoDeleted(demoId: string): DemoDeletedAction {
  return { type: DEMO_DELETED, payload: demoId };
}

export function productFeaturesReceived(
  product_features: Feature[]
): ProductFeaturesReceivedAction {
  return { type: PRODUCT_FEATURES_LIST_RECEIVED, payload: product_features };
}

export function productsReceived(products: Product[]): ProductsReceivedAction {
  return { type: PRODUCTS_LIST_RECEIVED, payload: products };
}

export function ownersReceived(owners: Owner[]): OwnersReceivedAction {
  return { type: OWNERS_LIST_RECEIVED, payload: owners };
}

export function usersReceived(users: User[]): UsersReceivedAction {
  return { type: USERS_LIST_RECEIVED, payload: users };
}
