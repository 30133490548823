import React, { useState, useEffect, useContext } from "react";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

import {
  demosReceived,
  productFeaturesReceived,
  ownersReceived,
  productsReceived,
  usersReceived,
  demoDeleted,
} from "../actions";
import DemoListItem from "../components/DemoListItem";
import { Demo } from "../types";
import DemoCreateFormContainer from "./DemoCreateFormContainer";
import { listDemos, listUsers, deleteDemo } from "../api_service";
import AppContext from "../app_context";
import {
  demoResponseItemToDemo,
  ownerResponseItemToOwner,
  featureResponseItemToFeature,
  productResponseItemToProduct,
  userResponseItemToUser,
} from "../api_service_data_mappers";

const styles = (theme: Theme) =>
  createStyles({
    demoContainer: {
      marginRight: 80,
      marginLeft: 80,
      [theme.breakpoints.down("sm")]: {
        marginRight: 40,
        marginLeft: 40,
      },
    },
    demoListItem: {
      marginBottom: "10px",
    },
    headerTitle: {
      minWidth: "max-content",
    },
    headerButton: {
      minWidth: "max-content",
    },
  });

export interface DemoListContainerProps extends WithStyles<typeof styles> {}

const DemoListContainer: React.FC<DemoListContainerProps> = ({ classes }) => {
  const { state, dispatch } = useContext(AppContext);
  const [requestState, setRequestState] = useState("not fetched");
  const [isDemoCreationFormOpen, setDemoCreationFormState] = useState(false);
  const { demos, features, owners } = state;

  function createNewDemoButtonClickHandler(evt: React.SyntheticEvent) {
    evt.preventDefault();
    setDemoCreationFormState(true);
  }

  // load the demos
  useEffect(() => {
    if (requestState !== "not fetched") {
      return;
    }
    const listDemosPromise = listDemos();
    const listUsersPromise = listUsers();
    Promise.all([listDemosPromise, listUsersPromise]).then((values) => {
      // the promises are ready at this point and should execute sequentially
      listDemosPromise
        .then((demoResponse) => {
          if (demoResponse) {
            dispatch(
              demosReceived(demoResponse.demos.map(demoResponseItemToDemo))
            );
            dispatch(
              ownersReceived(demoResponse.owners.map(ownerResponseItemToOwner))
            );
            dispatch(
              productFeaturesReceived(
                demoResponse.product_features.map(featureResponseItemToFeature)
              )
            );
            dispatch(
              productsReceived(
                demoResponse.products.map(productResponseItemToProduct)
              )
            );
          } else {
            setRequestState("error");
            return Promise.reject("unable to parse demos response");
          }
          return setRequestState("fetched");
        })
        .catch((err) => console.log(err));
    });
    listUsersPromise.then((usersResponse) => {
      if (usersResponse) {
        dispatch(
          usersReceived(usersResponse.users.map(userResponseItemToUser))
        );
      } else {
        setRequestState("error");
        return Promise.reject("unable to fetch users");
      }
    });
  }, []);

  if (demos.length === 0 && requestState === "not fetched") {
    return <h1>fetching...</h1>;
  } else {
    let now = new Date();
    let demosList = demos
      .filter((d: Demo) => d.scheduledTime >= now)
      .sort((a, b) => +a.scheduledTime - +b.scheduledTime)
      .map((d: Demo) => {
        const productFeatures = features.filter(
          (feature) => d.productFeaturesUuids.indexOf(feature.uuid) !== -1
        );
        const allOwnerUuids = productFeatures.reduce(
          (prev, feature) => prev.concat(feature.ownersUuids),
          [] as string[]
        );
        const productFeatureOwners = owners.filter(
          (owner) => allOwnerUuids.indexOf(owner.uuid) !== -1
        );
        return (
          <DemoListItem
            key={d.uuid}
            demo={d}
            features={productFeatures}
            owners={productFeatureOwners}
            onDemoCancelClicked={(demoId) => {
              dispatch(demoDeleted(demoId));
            }}
          />
        );
      });
    let [firstDemoItem, ...futureDemos] = demosList;
    return (
      <div className={classes.demoContainer}>
        <Grid container alignItems="center" spacing={2}>
          {/* header row that contains button and title*/}
          <Grid item xs={3} className={classes.headerTitle}>
            <Typography
              gutterBottom
              color="primary"
              variant="h2"
              component="h2"
            >
              Next Demo
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.headerButton}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={createNewDemoButtonClickHandler}
            >
              Add Demo
            </Button>
          </Grid>
        </Grid>
        <DemoCreateFormContainer
          open={isDemoCreationFormOpen}
          onCloseModal={() => setDemoCreationFormState(false)}
          onSubmitForm={() => {
            setDemoCreationFormState(false);
          }}
        />

        {firstDemoItem}

        <Typography
          style={{ marginTop: "20px" }}
          gutterBottom
          color="primary"
          variant="h3"
          component="h3"
        >
          Upcoming Demos
        </Typography>
        <Grid container direction="column">
          {futureDemos.map((demoListItem, i) => (
            <Grid item key={i} className={classes.demoListItem}>
              {demoListItem}
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
};

export default withStyles(styles)(DemoListContainer);
