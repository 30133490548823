import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/CancelScheduleSend";
import MessageIcon from "@material-ui/icons/MessageOutlined";

import { Demo, Feature, Owner } from "../types";
import { classnames } from "../utils";
import { deleteDemo, createDemoReminder } from "../api_service";

const demoItemStyles = (theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 800,
      padding: "5px",
      margin: "auto",
      overflow: "hidden",
      height: "156px",
    },
    demoItemGrid: {
      height: "100%",
    },
    demoItemColumn: {
      width: "31%",
      overflowX: "hidden",
    },
    demoFeaturesColumn: {
      maxWidth: "fit-content",
    },
    circularProgress: {
      position: "relative",
      right: "50%",
      verticalAlign: "text-top",
    },
  });

export interface DemoListItemProps extends WithStyles<typeof demoItemStyles> {
  demo: Demo;
  features: Feature[];
  owners: Owner[];
  onDemoCancelClicked: (demoId: string) => void;
}

const DemoListItem: React.FC<DemoListItemProps> = ({
  demo,
  features,
  owners,
  classes,
  onDemoCancelClicked,
}) => {
  // this component is no longer "dumb"
  const [cancelButtonIsDisabled, setCancelButtonIsDisabled] = React.useState(
    false
  );
  const [
    demoReminderButtonDisabled,
    setDemoReminderButtonDisabled,
  ] = React.useState(false);

  const [
    demoReminderResponseLoading,
    setDemoReminderResponseLoading,
  ] = React.useState(false);
  return (
    <Paper className={classes.paper}>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        className={classes.demoItemGrid}
      >
        <Grid
          item
          container
          direction="column"
          justify="space-evenly"
          className={classes.demoItemColumn}
        >
          <Grid item>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              align="left"
            >
              {demo.prospect}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              align="left"
            >
              {demo.scheduledTime.toLocaleString()}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              align="left"
            >
              {demo.location}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justify="space-evenly"
          className={classnames(
            classes.demoFeaturesColumn,
            classes.demoItemColumn
          )}
        >
          {/* list the first three items only these should be chips or something */}
          {features.slice(0, 3).map((feature) => (
            <Grid item key={feature.uuid}>
              <Typography
                variant="body1"
                component="p"
                color="textSecondary"
                align="left"
              >
                {feature.name}
              </Typography>
            </Grid>
          ))}
          <Grid item>
            {demo.productFeaturesUuids.length > 3 && (
              <Typography variant="body2" component="p" color="textSecondary">
                {demo.productFeaturesUuids.length - 3} more
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          alignItems="flex-end"
          justify="space-evenly"
          className={classes.demoItemColumn}
        >
          <Grid item>
            <Button
              style={{ marginRight: "21px" }}
              variant="outlined"
              startIcon={<DeleteIcon />}
              disabled={cancelButtonIsDisabled}
              onClick={() => {
                setCancelButtonIsDisabled(true);
                deleteDemo(demo.uuid).then(() => {
                  setCancelButtonIsDisabled(false);
                  onDemoCancelClicked(demo.uuid);
                });
              }}
              color="secondary"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<MessageIcon />}
              variant="outlined"
              color="primary"
              disabled={demoReminderButtonDisabled}
              onClick={() => {
                setDemoReminderButtonDisabled(true);
                setCancelButtonIsDisabled(true);
                setDemoReminderResponseLoading(true);
                createDemoReminder(demo.uuid)
                  .then(() => {
                    setTimeout(() => {
                      setDemoReminderButtonDisabled(false);
                      setCancelButtonIsDisabled(false);
                      setDemoReminderResponseLoading(false);
                    }, 1000);
                  })
                  .catch((err) => {
                    console.error(err);
                    setDemoReminderButtonDisabled(false);
                    setCancelButtonIsDisabled(false);
                    setDemoReminderResponseLoading(false);
                  });
              }}
            >
              Notify Owners
            </Button>
            {demoReminderResponseLoading && (
              <CircularProgress
                size={24}
                className={classes.circularProgress}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(demoItemStyles)(DemoListItem);
