import React, { useState, useReducer, useContext } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";

import AppContext from "../app_context";
import { productsReceived } from "../actions";
import { ProductFormInput } from "../types";
import { createProduct } from "../api_service";
import { productResponseItemToProduct } from "../api_service_data_mappers";
import OwnerCreateFormContainer from "./OwnerCreateFormContainer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const SelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const productCreateFormStyles = (theme: Theme) =>
  createStyles({
    ownerSelectionContainer: {
      display: "flex",
      alignItems: "baseline",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      marginLeft: 2,
    },
    formField: {
      margin: 20,
      width: "90%",
    },
    paper: {
      width: 700,
      padding: "12px",
      margin: "auto",
      overflow: "scroll",
      maxHeight: 600,
      minHeight: 500,
      marginTop: "50px",
    },
  });
interface ProductCreateFormProps
  extends WithStyles<typeof productCreateFormStyles> {
  open: boolean;
  onCloseModal: () => void;
  onSubmitForm: (newProductUuid: string) => void;
}

interface productFormState extends ProductFormInput {
  errors: { [K in keyof ProductFormInput]?: string | null };
}

const productFormInitialValues: productFormState = {
  name: "",
  description: "",
  ownerUuid: "",
  errors: {},
};

type formKeys = keyof ProductFormInput;

const productFormReducer = (
  state: productFormState,
  action: { name: formKeys | "RESET"; value: string }
) => {
  if (action.name === "RESET") {
    return { ...productFormInitialValues };
  }

  return { ...state, [action.name]: action.value };
};

const ProductCreateFormContainer: React.FC<ProductCreateFormProps> = ({
  open,
  onCloseModal,
  onSubmitForm,
  classes,
}) => {
  const [formState, formDispatch] = useReducer(
    productFormReducer,
    productFormInitialValues
  );
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isOwnerFormOpen, setIsOwnerFormOpen] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  if (!open) {
    return null;
  }
  const { owners } = state;
  return (
    <>
      <Dialog
        open={open}
        onClose={onCloseModal}
        scroll="paper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle disableTypography={true} id="product-create-form-title">
          <Typography variant="h4" component="h4">
            Add A Product
          </Typography>
        </DialogTitle>

        <DialogContent>
          <form className={classes.form}>
            <div className={classes.ownerSelectionContainer}>
              <FormControl variant="filled" className={classes.formField}>
                <InputLabel id="product-create-form-owner-label">
                  Product Owner
                </InputLabel>
                <Select
                  id="product-create-form-owner"
                  labelId="product-create-form-owner-label"
                  value={formState.ownerUuid}
                  onChange={(evt: React.ChangeEvent<{ value: unknown }>) =>
                    formDispatch({
                      name: "ownerUuid",
                      value: evt.target.value as string,
                    })
                  }
                  MenuProps={SelectMenuProps}
                >
                  {owners.map((owner) => (
                    <MenuItem key={owner.uuid} value={owner.uuid}>
                      {owner.email}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                onClick={() => setIsOwnerFormOpen(true)}
                fullWidth={false}
                color="default"
                variant="text"
                disabled={isFormSubmitting}
              >
                Add Owner
              </Button>
            </div>
            <TextField
              variant="filled"
              id="product-create-form-name"
              required
              className={classes.formField}
              label="Name"
              name="name"
              value={formState.name}
              error={!!formState.errors.name}
              helperText={
                formState.errors.name || "How do you refer to the product?"
              }
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                formDispatch({ name: "name", value: evt.target.value })
              }
            />
            <TextField
              id="product-create-form-description"
              variant="filled"
              required
              label="Description"
              className={classes.formField}
              value={formState.description}
              error={!!formState.errors.description}
              helperText={
                formState.errors.description ||
                "How would you describe this product?"
              }
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                formDispatch({ name: "description", value: evt.target.value });
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth={false}
            variant="outlined"
            color="secondary"
            disabled={isFormSubmitting}
            onClick={() => {
              /* TODO: hackish way of clearing form! */
              formDispatch({ name: "RESET", value: "" });
              onCloseModal();
            }}
          >
            Go Back
          </Button>
          <Button
            fullWidth={false}
            variant="contained"
            color="primary"
            disabled={isFormSubmitting}
            onClick={() => {
              setIsFormSubmitting(true);
              createProduct(formState).then((productResponse) => {
                dispatch(
                  productsReceived(
                    productResponse.products.map(productResponseItemToProduct)
                  )
                );
                onSubmitForm(productResponse.products[0].uuid);
                setIsFormSubmitting(false);
                formDispatch({ name: "RESET", value: "" });
              });
            }}
          >
            Add Product
          </Button>
        </DialogActions>
      </Dialog>
      <OwnerCreateFormContainer
        open={isOwnerFormOpen}
        onCloseModal={() => setIsOwnerFormOpen(false)}
        onSubmitForm={(ownerUuid) => {
          formDispatch({
            name: "ownerUuid",
            value: ownerUuid,
          });
          setIsOwnerFormOpen(false);
        }}
      />
    </>
  );
};

export default withStyles(productCreateFormStyles)(ProductCreateFormContainer);
